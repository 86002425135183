import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = ({ title, description, name, type, imageUrl, url }) => {
  // Ensure image URL is absolute (required for social media crawlers)
  const absoluteImageUrl = imageUrl && imageUrl.startsWith('http') 
    ? imageUrl 
    : imageUrl ? `${window.location.origin}${imageUrl.startsWith('/') ? '' : '/'}${imageUrl}` : '';
  
  // Use current URL if none provided
  const currentUrl = url || (typeof window !== 'undefined' ? window.location.href : '');

  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      
      {/* OpenGraph / Facebook */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={name} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:image" content={absoluteImageUrl} /> {/* Use absoluteImageUrl here */}
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={absoluteImageUrl} /> {/* Use absoluteImageUrl here */}
      <meta name="twitter:url" content={currentUrl} />
    </Helmet>
  );
};

export default SEO;