import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFirebase } from '../Context/FirebaseContext';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import NavBarComponent from '../Components/GeneralNav/Nav';
import SEO from '../Components/SEO';
import { FaCalendarAlt, FaClock, FaMapMarkerAlt, FaMoneyBillWave } from 'react-icons/fa';
import Footer from '../Components/Footer/Footer';
import EventNotFound from '../Layouts/Events/EventNotFound';
import ClearHeader from '../Layouts/PageHeader/ClearHeader';
import ScrollToTop from '../Components/ScrollToTop';

import logo from '../images/pcg.png'; // Adjust the path based on your file structure

const LoadingSpinner = () => (
    <div
        style={{
            height: '100dvh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '2rem'
        }}
    >
        <div style={{ position: 'relative', display: 'inline-block' }}>
            {/* Logo - Replace src with your actual logo path */}
            <img
                src={logo}
                alt="Logo"
                style={{
                    width: '60px',
                    height: '60px',
                    objectFit: 'contain',
                    position: 'relative',
                    zIndex: 2
                }}
            />
            {/* Spinner positioned absolutely around the logo */}
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100px',
                height: '100px'
            }}>
                <Spinner
                    animation="border"
                    variant="danger"
                    style={{
                        width: '100%',
                        height: '100%',
                        borderWidth: '4px',
                        opacity: 0.7
                    }}
                />
            </div>
        </div>
    </div>
);


const EventSingle = () => {
    const { eventid } = useParams();
    const { db } = useFirebase();
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchEvent = async () => {
            setLoading(true);
            try {
                const eventsRef = collection(db, 'events');
                const q = query(eventsRef, where('__name__', '==', eventid));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    setEvent({ id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() });
                }
            } catch (error) {
                console.error("Error fetching event:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchEvent();
    }, [db, eventid]);


    // With this:
    let socialDescription = 'Join us at Emmanuel Presbyterian Church Kasoa'; // Default value
    if (typeof event?.brief === 'string' && event.brief) {
        socialDescription = event.brief.substring(0, 150) + '...';
    }


    return (
        <>
            <ScrollToTop />
            <SEO
                title={`${event?.eventName || 'Event'} | EPC Kasoa`}
                description={socialDescription}
                name='Emmanuel Presbyterian Church'
                type='website'
                imageUrl={event?.imageUrl}
                url={window.location.href}
            />
            <NavBarComponent />
            <ClearHeader
                title={event?.eventName || 'Event'}
                breadcrumbs={['Home', 'Events', event?.id ]}
            />
            <div style={{ marginBottom: "100px" }}></div>

            {loading ? <LoadingSpinner /> : (
                <>
                    { !event ? <EventNotFound /> :
                    <Container className='alignleft text-dark'>
                        <h1 className='event-single-title' style={{ marginBottom: '10px' }}>{event.eventName}</h1>
                        {event.imageUrl && <img src={event.imageUrl} alt={event.eventName} width='100%' />}

                        <p className='event-single-description' dangerouslySetInnerHTML={{ __html: event.description }}></p>
                        <div className='mb-5'></div>
                        <h3>EVENT DETAILS</h3>
                        <Row>
                            <Col sm={4} xs={12} className="alignleft">
                                <p><FaCalendarAlt color='var(--redcolor)' /> <span>{event.startDate}</span></p>
                            </Col>
                            <Col sm={4} xs={12} className="alignleft">
                                <p><FaCalendarAlt color='var(--redcolor)' /> <span>{event.endDate}</span></p>
                            </Col>
                            <Col sm={4} xs={12} className="alignleft">
                                <p><FaClock color='var(--redcolor)' /> <span>{event.startTime}</span> - <span>{event.endTime}</span></p>
                            </Col>
                            <Col sm={4} xs={12} className="alignleft">
                                <p><FaMapMarkerAlt color='var(--redcolor)' /> <span>{event.venue}</span></p>
                            </Col>
                            <Col sm={4} xs={12} className="alignleft">
                                <p><FaMoneyBillWave color='var(--redcolor)' /> <span>{event.rate}</span></p>
                            </Col>
                        </Row>
                    </Container>
                    }
                </>
            )}


            <div style={{ marginTop: "20px", marginBottom: "50px" }}></div>
            <Footer />
        </>
    );
};

export default EventSingle;