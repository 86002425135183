import React, { useEffect } from "react";
import { updateMetaTags } from "./metaUtils";

const SocialMetaManager = ({ title, description, image, url }) => {
  useEffect(() => {
    updateMetaTags([
      // Open Graph tags
      { property: "og:title", content: title },
      { property: "og:description", content: description },
      { property: "og:image", content: image },
      { property: "og:url", content: url },
      { property: "og:type", content: "website" },
      
      // Twitter Card tags
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:title", content: title },
      { name: "twitter:description", content: description },
      { name: "twitter:image", content: image },
      { name: "twitter:url", content: url },
      
      // Standard SEO description tag
      { name: "description", content: description }
    ]);
  }, [title, description, image, url]);

  return null;
};

export default SocialMetaManager;
