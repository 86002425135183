import React, { useState, useEffect, useCallback } from 'react';
import NavBarComponent from '../Components/GeneralNav/Nav';
import Footer from '../Components/Footer/Footer';
import PageHeader from '../Layouts/PageHeader/PageHeader';
import HeaderImage from "../images/headers/s2.jpg"
import SEO from '../Components/SEO';
import { Form, Button, Container, Dropdown, Row, Col, Spinner } from 'react-bootstrap';
import { FaSearch, FaBars, FaTimes } from 'react-icons/fa';
import '../Layouts/Events/Events.css';
import { motion, AnimatePresence } from 'framer-motion';
import EventList from '../Layouts/Events/EventList';
import MonthCalendar from '../Layouts/Events/MonthCalendar';
import { useFirebase } from '../Context/FirebaseContext';
import { collection, query, where, getDocs, orderBy, startAfter, limit } from 'firebase/firestore';
import DailyEventsList from '../Layouts/Events/DailyEventsList';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import ClearHeader from '../Layouts/PageHeader/ClearHeader';
import ScrollToTop from '../Components/ScrollToTop';
import logo from '../images/pcg.png'; // Adjust the path based on your file structure


const LoadingSpinner = () => (
  <div
    style={{
      height: '100dvh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '2rem'
    }}
  >
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {/* Logo - Replace src with your actual logo path */}
      <img
        src={logo}
        alt="Logo"
        style={{
          width: '60px',
          height: '60px',
          objectFit: 'contain',
          position: 'relative',
          zIndex: 2
        }}
      />
      {/* Spinner positioned absolutely around the logo */}
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100px',
        height: '100px'
      }}>
        <Spinner
          animation="border"
          variant="danger"
          style={{
            width: '100%',
            height: '100%',
            borderWidth: '4px',
            opacity: 0.7
          }}
        />
      </div>
    </div>
  </div>
);

const ListView = () => <EventList />;
const MonthView = () => <MonthCalendar />;
const DayView = () => <DailyEventsList />;

const TabContent = ({ tab }) => {
  const views = {
    list: ListView,
    month: MonthView,
    day: DayView
  };
  const SelectedView = views[tab];

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={tab}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <SelectedView />
      </motion.div>
    </AnimatePresence>
  );
};

const Events = () => {
  const { db } = useFirebase();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('list');
  const [showSearch, setShowSearch] = useState(false);
  const [events, setEvents] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSearchPage, setShowSearchPage] = useState(false);
  const [lastFetchedEventDate, setLastFetchedEventDate] = useState(null);

  const toggleSearch = () => setShowSearch(!showSearch);

  const fetchEvents = useCallback(async (searchTerm, loadMore = false) => {
    setLoading(true);
    try {
      let q = query(
        collection(db, 'events'),
        where('deleted', '==', false),
        where('status', '==', 'active'),
        orderBy('startDate'),
        limit(5)  // Increased limit to get more potential matches
      );

      if (loadMore && lastFetchedEventDate) {
        q = query(q, where('startDate', '>', lastFetchedEventDate));
      }

      const querySnapshot = await getDocs(q);

      // Client-side filtering
      const filteredEvents = querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(event => {
          const lowercaseEventName = event.eventName.toLowerCase();
          const lowercaseSearchTerm = searchTerm.toLowerCase();
          return lowercaseEventName.includes(lowercaseSearchTerm);
        });

      if (loadMore) {
        setEvents(prevEvents => [...prevEvents, ...filteredEvents]);
      } else {
        setEvents(filteredEvents);
      }

      const lastVisibleEvent = querySnapshot.docs[querySnapshot.docs.length - 1];
      if (lastVisibleEvent) {
        setLastFetchedEventDate(lastVisibleEvent.data().startDate);
      }

      setHasMore(querySnapshot.docs.length === 10);
      setShowSearchPage(true);
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      setLoading(false);
    }
  }, [db, lastFetchedEventDate]);

  const handleEventClick = (eventname, eventId) => {
    navigate(`/events/${createSlug(eventname)}/${eventId}`);
  };

  const createSlug = (string) => {
    string = string.toLowerCase();
    string = string.replace(/[^a-z0-9 ]/g, '');
    string = string.replace(/\s+/g, '-');
    string = string.replace(/^-|-$/g, '');
    if (string === '') {
      return 'slug';
    }

    return string;
  }

  const handleSearch = (e) => {
    e.preventDefault();
    setLastFetchedEventDate(null);
    setHasMore(true);
    fetchEvents(searchTerm);
  };

  const handleLoadMore = () => {
    fetchEvents(searchTerm, true);
  };

  const renderEvents = () => (
    events.map((event) => (
      <Row key={event.id} className='event-card'>
        <Col sm={2} xs={2} className="alignleft">
          <div className="date-tag">
            <div className="month">{format(new Date(event.startDate), 'MMM')}</div>
            <div className="day">{format(new Date(event.startDate), 'd')}</div>
          </div>
        </Col>
        <Col sm={3} xs={10} className="alignleft order-xs-2 order-sm-3" style={{ overflow: "hidden" }}>
          <img src={event.imageUrl} style={{ maxWidth: "300px", width: "100%", aspectRatio: 'auto' }} alt={event.eventName} />
        </Col>
        <Col xs={2} className="alignleft d-xs-block d-sm-none"></Col>
        <Col sm={7} xs={10} className="alignleft order-xs-3 order-sm-2 ">
          <p>{format(new Date(event.startDate), 'MMMM d @ h:mm a')} - {format(new Date(event.endDate), 'h:mm a')}</p>
          <h2><span class='event-name-link' onClick={() => handleEventClick(event.eventName, event.id)}>{event.eventName}</span></h2>
          <p><span style={{ fontWeight: '600' }}>{event.venue}</span></p>
          <p>{event.brief}</p>
          <p>{event.rate}</p>
        </Col>
      </Row>
    ))
  );


  return (
    <>
      <ScrollToTop />
      <SEO
        title='Events'
        description='The official website of the Emmanuel Presbyterian Church Kasoa'
        name='Emmanuel Presbyterian Church.'
        type='events'
      />
      <NavBarComponent />
      <ClearHeader
        title='Events'
        breadcrumbs={['Home', 'Events']}
      />
      <div style={{ marginBottom: "100px" }}></div>

      {loading ? <LoadingSpinner /> : (
        <>
          <Container className="search-container d-none d-sm-flex">
            <Form className="search-form" onSubmit={handleSearch}>
              <div className="search-wrapper">
                <FaSearch style={{ color: "rgb(169, 184, 195)", fontSize: '1.2rem' }} />
                <Form.Control
                  type="text"
                  placeholder="Search..."
                  className="search-input"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Button variant="primary" className="search-button" type="submit" style={{
                  padding: 'clamp(12px, 1.2vw, 18px) clamp(24px, 2.2vw, 36px)',
                  fontSize: 'clamp(14px, 1vw, 18px)',
                  backgroundColor: 'transparent',
                  color: 'var(--txtcolor)',
                  border: '2px solid var(--redcolor)',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  fontWeight: 600,
                  transition: 'all 0.3s ease',
                }}>
                  FIND EVENTS
                </Button>
              </div>
            </Form>
            <div className='btn-holder'>
              {['list', 'month', 'day'].map((tab) => (
                <Button
                  key={tab}
                  variant="link"
                  size="sm"
                  className={`tab-button ${activeTab === tab ? 'active' : ''}`}
                  onClick={() => { setActiveTab(tab); setShowSearchPage(false); }}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </Button>
              ))}
            </div>
          </Container>

          {/* Mobile view controls */}
          <Container className="d-sm-none mt-3">
            <div className="d-flex justify-content-between">
              <Button variant="outline-primary" onClick={toggleSearch} style={{ color: 'var(--bluecolor)', border: "none" }}>
                <FaSearch />
              </Button>
              <Dropdown>
                <Dropdown.Toggle variant="outline-primary" id="dropdown-basic" >
                  <FaBars />
                </Dropdown.Toggle>
                <Dropdown.Menu >
                  {['list', 'month', 'day'].map((tab) => (
                    <Dropdown.Item
                      key={tab}
                      onClick={() => { setActiveTab(tab); setShowSearchPage(false); }}
                      active={activeTab === tab}
                    >
                      {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Container>

          {/* Mobile search bar */}
          <AnimatePresence>
            {showSearch && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="d-sm-none"
              >
                <Container className="mt-3">
                  <Form className="search-form" onSubmit={handleSearch}>
                    <div className="search-wrapper">
                      <FaSearch style={{ color: "rgb(169, 184, 195)", fontSize: '1.2rem' }} />
                      <Form.Control
                        type="text"
                        placeholder="Search..."
                        className="search-input"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <Button variant="primary" className="search-button" type="submit">
                        FIND
                      </Button>
                    </div>
                  </Form>
                </Container>
              </motion.div>
            )}
          </AnimatePresence>
          {!showSearchPage ?
            <Container className="event-list  text-dark nobg" >
              <div style={{ marginTop: "50px", paddingLeft: "5%", paddingRight: '5%' }}>
                <TabContent tab={activeTab} />
              </div>
            </Container>
            :
            <Container className="event-list  text-dark nobg" >
              <div className='nobg' style={{ marginTop: "50px", paddingLeft: "5%", paddingRight: '5%', }}>
                {renderEvents()}
                {loading && <p>Loading events...</p>}
                {!loading && events.length === 0 && <p>No events found. Try a different search.</p>}
                {!loading && hasMore && events.length > 0 && (
                  <div className="text-center mt-4">
                    <Button variant="primary" onClick={handleLoadMore}>
                      Load More
                    </Button>
                  </div>
                )}
                {!hasMore && events.length > 0 && <p className="text-center mt-4">No more events to load.</p>}
              </div>
            </Container>
          }
        </>
      )}


      <div style={{ marginTop: "20px", marginBottom: "50px" }}></div>
      <Footer />
    </>
  );
};

export default Events;