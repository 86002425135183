import React, { useState, useEffect, useRef } from 'react';
import NavBarComponent from '../Components/GeneralNav/Nav';
import Footer from '../Components/Footer/Footer';
import { Container, Spinner, Row, Col, Button, Modal } from 'react-bootstrap';
import PageHeader from '../Layouts/PageHeader/PageHeader';
import HeaderImage from "../images/headers/s2.jpg"
import SEO from '../Components/SEO';
import ClearHeader from '../Layouts/PageHeader/ClearHeader';
import ScrollToTop from '../Components/ScrollToTop';
import logo from '../images/pcg.png'; // Adjust the path based on your file structure
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TeamMemberCard from '../Layouts/Leadership/TeamMemberCard'; // Assuming you still need this
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { useFirebase } from '../Context/FirebaseContext';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';


const LoadingSpinner = () => (
  <div
    style={{
      height: '100dvh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '2rem'
    }}
  >
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {/* Logo - Replace src with your actual logo path */}
      <img
        src={logo}
        alt="Logo"
        style={{
          width: '60px',
          height: '60px',
          objectFit: 'contain',
          position: 'relative',
          zIndex: 2
        }}
      />
      {/* Spinner positioned absolutely around the logo */}
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100px',
        height: '100px'
      }}>
        <Spinner
          animation="border"
          variant="danger"
          style={{
            width: '100%',
            height: '100%',
            borderWidth: '4px',
            opacity: 0.7
          }}
        />
      </div>
    </div>
  </div>
);


const Leadership = () => {
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true); // For initial page load

  // Pastors Component States
  const [currentMinister, setCurrentMinister] = useState(null);
  const [pastMinisters, setPastMinisters] = useState([]);
  const [pastorsError, setPastorsError] = useState(null);
  const [expandedPastorProfile, setExpandedPastorProfile] = useState(false);
  const [showPastMinistersModal, setShowPastMinistersModal] = useState(false);
  const pastorProfileRef = useRef(null);

  // Presbytery List States
  const [presbyters, setPresbyters] = useState([]);
  const [presbyteryError, setPresbyteryError] = useState(null);

  // Session List States
  const [sessionMembers, setSessionMembers] = useState([]);
  const [sessionError, setSessionError] = useState(null);

  // Admin List States
  const [admins, setAdmins] = useState([]);
  const [adminError, setAdminError] = useState(null);

  const { db } = useFirebase();

  useEffect(() => {
    const fetchData = async () => {
      setPageLoading(true);
      setLoading(true);
      try {
        await Promise.all([
          fetchCurrentMinisterData(),
          fetchPresbytersData(),
          fetchSessionMembersData(),
          fetchAdminData(),
        ]);
      } catch (error) {
        console.error("Error fetching leadership data:", error);
        // Optionally set a general error state if needed
      } finally {
        setLoading(false);
        setPageLoading(false);
      }
    };

    fetchData();
  }, [db]);


  // Pastors Component Functions
  const fetchCurrentMinisterData = async () => {
    try {
      const currentMinisterQuery = query(collection(db, 'currentMinister'), limit(1));
      const currentMinisterSnapshot = await getDocs(currentMinisterQuery);

      if (currentMinisterSnapshot.empty) {
        setPastorsError('No current minister found');
        return;
      }

      const currentMinisterData = currentMinisterSnapshot.docs[0].data();
      const ministerId = currentMinisterData.ministerId;

      const ministerQuery = query(collection(db, 'ministers'), where('id', '==', ministerId));
      const ministerSnapshot = await getDocs(ministerQuery);

      if (ministerSnapshot.empty) {
        setPastorsError('Minister details not found');
        return;
      }

      setCurrentMinister(ministerSnapshot.docs[0].data());
    } catch (err) {
      console.error('Error fetching current minister:', err);
      setPastorsError('Failed to fetch current minister data');
    }
  };

  const fetchPastMinistersData = async () => {
    try {
      const ministersQuery = query(
        collection(db, 'ministers'),
        orderBy('endTenure', 'desc')
      );
      const ministersSnapshot = await getDocs(ministersQuery);

      const ministers = ministersSnapshot.docs
        .map(doc => doc.data())
        .filter(minister => minister.endTenure); // Only include ministers with an end tenure

      setPastMinisters(ministers);
    } catch (err) {
      console.error('Error fetching past ministers:', err);
      setPastorsError('Failed to fetch past ministers');
    }
  };

  const handleShowPastMinistersModal = async () => {
    if (pastMinisters.length === 0) {
      await fetchPastMinistersData();
    }
    setShowPastMinistersModal(true);
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  const toggleExpandPastorProfile = () => {
    setExpandedPastorProfile(!expandedPastorProfile);
  };


  // Presbytery List Functions
  const fetchPresbytersData = async () => {
    try {
      const groupsQuery = query(collection(db, 'groups'), where('type', '==', 'presbyters'));
      const groupSnapshot = await getDocs(groupsQuery);

      if (groupSnapshot.empty) {
        setPresbyteryError(''); // No presbyters group found, not an error
        return;
      }

      const presbyteryGroupId = groupSnapshot.docs[0].id;

      const membersQuery = query(
        collection(db, 'members'),
        where('group', 'array-contains', presbyteryGroupId)
      );
      const membersSnapshot = await getDocs(membersQuery);

      const presbytersData = membersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        role: 'Presbyter'
      }));

      setPresbyters(presbytersData);
    } catch (err) {
      console.error("Error fetching presbyters:", err);
      setPresbyteryError('Failed to fetch presbyters');
    }
  };


  // Session List Functions
  const fetchSessionMembersData = async () => {
    try {
      const groupsQuery = query(collection(db, 'groups'), where('type', '==', 'session'));
      const groupSnapshot = await getDocs(groupsQuery);

      if (groupSnapshot.empty) {
        setSessionError(''); // No session group found, not an error
        return;
      }

      const sessionGroupId = groupSnapshot.docs[0].id;

      const membersQuery = query(
        collection(db, 'members'),
        where('group', 'array-contains', sessionGroupId)
      );
      const membersSnapshot = await getDocs(membersQuery);

      const sessionMembersData = membersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        role: 'Session Member'
      }));

      setSessionMembers(sessionMembersData);
    } catch (err) {
      console.error("Error fetching session members:", err);
      setSessionError('Failed to fetch session members');
    }
  };


  // Admin List Functions
  const fetchAdminData = async () => {
    try {
      const groupsQuery = query(collection(db, 'groups'), where('type', '==', 'admin'));
      const groupSnapshot = await getDocs(groupsQuery);

      if (groupSnapshot.empty) {
        setAdminError(''); // No admin group found, not an error
        return;
      }

      const adminGroupId = groupSnapshot.docs[0].id;

      const membersQuery = query(
        collection(db, 'members'),
        where('group', 'array-contains', adminGroupId)
      );
      const membersSnapshot = await getDocs(membersQuery);

      const adminData = membersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        role: 'Administration'
      }));

      setAdmins(adminData);
    } catch (err) {
      console.error("Error fetching admins:", err);
      setAdminError('Failed to fetch admins');
    }
  };


  const responsiveCarousel = {
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }


  if (pageLoading) {
    return <LoadingSpinner />;
  }


  return (
    <>
      <ScrollToTop />
      <SEO
        title='Leadership'
        description='The official website of the Emmanuel Presbyterian Church Kasoa'
        name='Emmnuel Presbyterian Church.'
        type='profile'
      />
      <NavBarComponent />
      <ClearHeader
        title="Our Team"
        breadcrumbs={['Home', 'Leaders']}
      />
      {loading ? <LoadingSpinner /> : (
        <Container fluid style={{ paddingLeft: "0px", paddingRight: "0px" }}>

          {/* Pastors Component */}
          <div style={{ margin: "50px 5%", overflow: "hidden" }}>
            <Row className="h-100" style={{ textAlign: "left" }}>
              <Col sm={7} className="d-flex flex-column">
                <h2 style={{ marginBottom: "40px" }}>
                  RESIDENT MINISTER
                </h2>

                {pastorsError && <p className="text-danger">{pastorsError}</p>}
                {currentMinister && (
                  <>
                    <h3>
                      {currentMinister.name}
                    </h3>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '80px',
                      marginBottom: '20px'
                    }}>
                      {currentMinister.facebook && <a href={currentMinister.facebook} target="_blank" rel="noopener noreferrer"><FaFacebookF size={14} /></a>}
                      {currentMinister.twitter && <a href={currentMinister.twitter} target="_blank" rel="noopener noreferrer"><FaTwitter size={14} /></a>}
                      {currentMinister.instagram && <a href={currentMinister.instagram} target="_blank" rel="noopener noreferrer"><FaInstagram size={14} /></a>}
                      {currentMinister.linkedin && <a href={currentMinister.linkedin} target="_blank" rel="noopener noreferrer"><FaLinkedinIn size={14} /></a>}
                    </div>

                    <p style={{
                      lineHeight: '22px',
                      color: '#000000',
                      maxHeight: expandedPastorProfile ? 'none' : '88px',
                      overflow: 'hidden'
                    }} ref={pastorProfileRef}>
                      {expandedPastorProfile ? currentMinister.profile : truncateText(currentMinister.profile, 300)}
                    </p>

                    <span
                      style={{
                        fontStyle: 'italic',
                        fontSize: '12px',
                        color: '#FA0523',
                        marginBottom: '20px',
                        cursor: 'pointer'
                      }}
                      onClick={toggleExpandPastorProfile}
                    >
                      {expandedPastorProfile ? 'Read less' : 'Read more'}
                    </span>

                    <Button
                      onClick={handleShowPastMinistersModal}
                      className="past-ministers-btn"
                      style={{
                        padding: 'clamp(12px, 1.2vw, 18px) clamp(24px, 2.2vw, 36px)',
                        fontSize: 'clamp(14px, 1vw, 18px)',
                        backgroundColor: 'transparent',
                        color: 'var(--txtcolor)',
                        border: '2px solid var(--redcolor)',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        fontWeight: 600,
                        transition: 'all 0.3s ease',
                        width: '100%',
                        '@media (min-width: 576px)': {
                          width: '150px'
                        }
                      }}
                    >
                      Past Ministers
                    </Button>
                  </>
                )}
              </Col>

              <Col sm={5}>
                <div>
                  {currentMinister && <img src={currentMinister.profilePicUrl} alt="resident minister" height="auto" width="100%" style={{ maxWidth: '400px' }} />}
                </div>
              </Col>
            </Row>
          </div>

          <Modal
            show={showPastMinistersModal}
            onHide={() => setShowPastMinistersModal(false)}
            size="lg"
            dialogClassName="past-ministers-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: '1.25rem' }}>Past Ministers</Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-2" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
              <Row className="mx-0">
                {pastMinisters.map((minister, index) => (
                  <Col xs={12} key={minister.id} className="px-2">
                    <div
                      className="d-flex align-items-center py-3"
                      style={{
                        borderBottom: index !== pastMinisters.length - 1 ? '1px solid #dee2e6' : 'none'
                      }}
                    >
                      <div
                        className="minister-image-container"
                        style={{
                          width: '80px',
                          height: '80px',
                          flexShrink: 0,
                          marginRight: '15px',
                          position: 'relative',
                          overflow: 'hidden',
                          borderRadius: '4px'
                        }}
                      >
                        <img
                          src={minister.profilePicUrl}
                          alt={minister.name}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                          }}
                        />
                      </div>

                      <div className="minister-info flex-grow-1">
                        <h5
                          className="mb-1"
                          style={{
                            fontSize: '1rem',
                            fontWeight: '600'
                          }}
                        >
                          {minister.name}
                        </h5>

                        <div
                          className="tenure-period mb-2"
                          style={{
                            fontSize: '0.875rem',
                            color: '#666'
                          }}
                        >
                          {new Date(minister.startTenure).getFullYear()} - {new Date(minister.endTenure).getFullYear()}
                        </div>

                        <div
                          className="social-links"
                          style={{
                            display: 'flex',
                            gap: '12px',
                            fontSize: '0.875rem'
                          }}
                        >
                          {minister.facebook && (
                            <a
                              href={minister.facebook}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: '#666' }}
                            >
                              <FaFacebookF size={12} />
                            </a>
                          )}
                          {minister.twitter && (
                            <a
                              href={minister.twitter}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: '#666' }}
                            >
                              <FaTwitter size={12} />
                            </a>
                          )}
                          {minister.instagram && (
                            <a
                              href={minister.instagram}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: '#666' }}
                            >
                              <FaInstagram size={12} />
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                size="sm"
                onClick={() => setShowPastMinistersModal(false)}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <style>
            {`
              @media (max-width: 575px) {
                .past-ministers-btn {
                  width: 100% !important;
                }
              }
              @media (min-width: 576px) {
                .past-ministers-btn {
                  width: 200px !important;
                }
              }

              .past-ministers-modal .modal-body::-webkit-scrollbar {
                width: 6px;
              }

              .past-ministers-modal .modal-body::-webkit-scrollbar-track {
                background: #f1f1f1;
                border-radius: 3px;
              }

              .past-ministers-modal .modal-body::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 3px;
              }

              .past-ministers-modal .modal-body::-webkit-scrollbar-thumb:hover {
                background: #555;
              }

              .minister-info a:hover {
                color: var(--redcolor) !important;
                transition: color 0.2s ease;
              }
            `}
          </style>


          {/* Presbytery List */}
          <Container fluid className="presbytery-list bg-dark text-light py-5">
            <Row className="justify-content-between align-items-center mb-4">
              <Col style={{ paddingLeft: "5%", paddingRight: '5%' }}>
                <h2 className="text-light" style={{ textAlign: "left" }}>PRESBYTERS</h2>
                <h3 className="text-danger" style={{ textAlign: "left" }}>Current</h3>
              </Col>
              <Col xs="auto" style={{ paddingRight: "50px" }}>
                {/* Past Presbyters Button - Functionality can be added later */}
              </Col>
            </Row>
            {presbyteryError && <p className="text-danger text-center">{presbyteryError}</p>}
            {presbyters.length > 0 ? (
              <Carousel
                responsive={responsiveCarousel}
                swipeable={true}
                draggable={false}
                infinite={true}
                autoPlay={true}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                itemClass="carousel-item-padding-40-px"
                keyBoardControl={true}
              >
                {presbyters.map((presbyter) => (
                  <div key={presbyter.id} className="d-flex justify-content-center">
                    <TeamMemberCard
                      name={`${capitalizeFirstLetter(presbyter.title)} ${presbyter.firstName} ${presbyter.lastName}`}
                      role={presbyter.role}
                      imageUrl={presbyter.profilePicUrl}
                    />
                  </div>
                ))}
              </Carousel>
            ) : !presbyteryError && !loading ? (
              <Container className="text-center mt-5">
                 {/* Optional: Message when no presbyters are found, but no error */}
              </Container>
            ) : null}
          </Container>


          {/* Session List */}
          <Container fluid className="presbytery-list bg-light text-light py-5">
            <Row className="justify-content-between align-items-center mb-4">
              <Col style={{ paddingLeft: "5%", paddingRight: '5%' }}>
                <h2 className="text-dark" style={{ textAlign: "left" }}>SESSION MEMBERS</h2>
                <h3 className="text-danger" style={{ textAlign: "left" }}>Current</h3>
              </Col>
              <Col xs="auto" style={{ paddingRight: "50px" }}>
                {/* Past Session Members Button - Functionality can be added later */}
              </Col>
            </Row>
            {sessionError && <p className="text-danger text-center">{sessionError}</p>}
            {sessionMembers.length > 0 ? (
              <Carousel
                responsive={responsiveCarousel}
                swipeable={true}
                draggable={false}
                infinite={true}
                autoPlay={true}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                itemClass="carousel-item-padding-40-px"
                keyBoardControl={true}
              >
                {sessionMembers.map((member) => (
                  <div key={member.id} className="d-flex justify-content-center">
                    <TeamMemberCard
                      name={`${capitalizeFirstLetter(member.title)} ${member.firstName} ${member.lastName}`}
                      role={member.role}
                      imageUrl={member.profilePicUrl}
                    />
                  </div>
                ))}
              </Carousel>
            ) : !sessionError && !loading ? (
              <Container className="text-center mt-5">
                 {/* Optional: Message when no session members are found, but no error */}
              </Container>
            ) : null}
          </Container>


          {/* Admin List */}
          <Container fluid className="presbytery-list bg-light text-light py-5">
            <Row className="justify-content-between align-items-center mb-4">
              <Col style={{ paddingLeft: "50px" }}>
                <h2 className="text-light" style={{ textAlign: "left" }}>ADMINISTRATION</h2>
                <h3 className="text-danger" style={{ textAlign: "left" }}>Current</h3>
              </Col>
              <Col xs="auto" style={{ paddingRight: "50px" }}>
                {/* Past Admin Members Button - Functionality can be added later */}
              </Col>
            </Row>
            {adminError && <p className="text-danger text-center">{adminError}</p>}
            {admins.length > 0 ? (
              <Carousel
                responsive={responsiveCarousel}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
              >
                {admins.map((admin) => (
                  <div key={admin.id} className="d-flex justify-content-center">
                    <TeamMemberCard
                      name={`${capitalizeFirstLetter(admin.title)} ${admin.firstName} ${admin.lastName}`}
                      role={admin.role}
                      imageUrl={admin.profilePicUrl}
                    />
                  </div>
                ))}
              </Carousel>
            ) : !adminError && !loading ? (
              <Container className="text-center mt-5">
                 {/* Optional: Message when no admins are found, but no error */}
              </Container>
            ) : null}
          </Container>

        </Container>
      )}
      <Footer />
    </>
  );
};

export default Leadership;