import React, { useState, useEffect } from 'react';
import { useFirebase } from '../Context/FirebaseContext';
import { doc, getDoc } from 'firebase/firestore';
import TeamMemberCard from '../Layouts/Leadership/TeamMemberCard';

const MemberCard = ({ memberId }) => {
    const { db } = useFirebase();
    const [memberData, setMemberData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMemberData = async () => {
        try {
            const docRef = doc(db, 'members', memberId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
            setMemberData(docSnap.data());
            } else {
            setError('Member data not found');
            }
        } catch (err) {
            console.error('Error fetching member data:', err);
            setError('Failed to fetch member data');
        } finally {
            setLoading(false);
        }
        };

        fetchMemberData();
    }, [db, memberId]);

    if (loading) {
        return <span>Loading...</span>;
    }

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    if (!memberData) {
        return null;
    }

    return (
        <TeamMemberCard
            name={`${memberData.firstName} ${memberData.lastName}`}
            role={memberData.position}
            imageUrl={memberData.profilePicUrl}
            socialLinks={{
                facebook: memberData.facebook ? memberData.facebook : "https://facebook.com/",
                twitter:  memberData.facebook ? memberData.twitter :"https://twitter.com/",
                instagram:  memberData.facebook ? memberData.instagram :"https://instagram.com/",
                youtube:  memberData.facebook ? memberData.youtube : "https://youtube.com/"
            }}
        />
    );
};

export default MemberCard;