import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useFirebase } from '../Context/FirebaseContext';
import {
    collection,
    doc,
    getDoc,
    getDocs,
    setDoc,
    updateDoc,
    arrayUnion,
    serverTimestamp,
    query,
    orderBy,
    increment,
    addDoc,
    where
} from 'firebase/firestore';
import { Container, Row, Col, Button, Form, Spinner } from 'react-bootstrap';
import NavBarComponent from '../Components/GeneralNav/Nav';
import SEO from '../Components/SEO';
import { FaCalendarAlt, FaThumbsUp, FaReply } from 'react-icons/fa';
import Footer from '../Components/Footer/Footer';
import ParallaxHeader from '../Layouts/General/ParallaxHeader';
import BlogNotFound from '../Layouts/Blog/BlogNotFound';
import ClearHeader from '../Layouts/PageHeader/ClearHeader';
import ScrollToTop from '../Components/ScrollToTop';

import logo from '../images/pcg.png'; // Adjust the path based on your file structure

const LoadingSpinner = () => (
    <div
        style={{
            height: '100dvh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '2rem'
        }}
    >
        <div style={{ position: 'relative', display: 'inline-block' }}>
            {/* Logo - Replace src with your actual logo path */}
            <img
                src={logo}
                alt="Logo"
                style={{
                    width: '60px',
                    height: '60px',
                    objectFit: 'contain',
                    position: 'relative',
                    zIndex: 2
                }}
            />
            {/* Spinner positioned absolutely around the logo */}
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100px',
                height: '100px'
            }}>
                <Spinner
                    animation="border"
                    variant="danger"
                    style={{
                        width: '100%',
                        height: '100%',
                        borderWidth: '4px',
                        opacity: 0.7
                    }}
                />
            </div>
        </div>
    </div>
);


const BlogSingle = () => {
    const { blogid } = useParams();
    const { db, user } = useFirebase();
    const [blog, setBlog] = useState(null);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [commentName, setCommentName] = useState('');
    const [commentEmail, setCommentEmail] = useState('');
    const [replyingTo, setReplyingTo] = useState(null);
    const [replyContent, setReplyContent] = useState('');
    const [replyName, setReplyName] = useState('');
    const [replyEmail, setReplyEmail] = useState('');
    const [loading, setLoading] = useState(true);
    const [authorPublications, setAuthorPublications] = useState([]);

    useEffect(() => {
        const fetchBlogAndComments = async () => {
            setLoading(true);
            try {
                const blogDoc = await getDoc(doc(db, 'blog', blogid));
                if (blogDoc.exists()) {
                    setBlog({ id: blogDoc.id, ...blogDoc.data() });

                    const commentsQuery = query(
                        collection(db, `blog/${blogid}/comments`),
                        where('approved', '==', true),
                        orderBy('createdAt', 'desc')
                    );
                    const commentsSnapshot = await getDocs(commentsQuery);
                    const commentsData = await Promise.all(commentsSnapshot.docs.map(async (doc) => {
                        const comment = { id: doc.id, ...doc.data() };
                        const repliesQuery = query(
                            collection(db, `blog/${blogid}/comments/${doc.id}/replies`),
                            where('approved', '==', true),
                            orderBy('createdAt', 'asc')
                        );
                        const repliesSnapshot = await getDocs(repliesQuery);
                        comment.replies = repliesSnapshot.docs.map(replyDoc => ({ id: replyDoc.id, ...replyDoc.data() }));
                        return comment;
                    }));
                    setComments(commentsData);

                    // Fetch other publications by the same author
                    const authorQuery = query(
                        collection(db, 'blog'),
                        where('author.id', '==', blog.author.id),
                        where('status', '==', 'publish')
                    );
                    const authorDocs = await getDocs(authorQuery);
                    const publications = authorDocs.docs
                        .map(doc => ({ id: doc.id, ...doc.data() }))
                        .filter(pub => pub.id !== blogid); // Exclude the current blog
                    setAuthorPublications(publications);
                }
            } catch (error) {
                console.error("Error fetching blog and comments: ", error);
            } finally {
                setLoading(false);
            }
        };
        fetchBlogAndComments();
    }, [db, blogid]);

    const handleCommentSubmit = async (e) => {
       // console.log(blogid);
        e.preventDefault();
        if (!commentName || !commentEmail || !newComment) {
            alert('Please fill in all fields.');
            return;
        }
        try {
            // Get reference to the comments collection
            const commentsRef = collection(db, `blog/${blogid}/comments`);

            // Add the comment document
            const docRef = await addDoc(commentsRef, {
                name: commentName,
                email: commentEmail,
                content: newComment,
                createdAt: serverTimestamp(),
                likes: 0,
                approved: false
            });

            //console.log( docRef.id);
            //return docRef.id;
            // Add to blogCommentLog collection using the comment's document ID
            await setDoc(doc(db, 'blogCommentLog', docRef.id), {
                blogId: blogid,
                commentId: docRef.id,
            });

            setNewComment('');
            setCommentName('');
            setCommentEmail('');
            alert('Your comment has been submitted for approval.');
        } catch (error) {
            console.error("Error adding comment: ", error);
            alert('There was an error submitting your comment. Please try again.');
        }
    };

    const handleReplySubmit = async (commentId) => {
        if (!replyName || !replyEmail || !replyContent) {
            alert('Please fill in all fields.');
            return;
        }
        try {
            const replyRef = await setDoc(doc(collection(db, `blog/${blogid}/comments/${commentId}/replies`)), {
                name: replyName,
                email: replyEmail,
                content: replyContent,
                createdAt: serverTimestamp(),
                likes: 0,
                approved: false, // Set to false by default
            });

            // Update the parent comment to include the new reply ID
            const commentRef = doc(db, `blog/${blogid}/comments`, commentId);
            await updateDoc(commentRef, {
                replies: arrayUnion(replyRef.id)
            });

            setReplyContent('');
            setReplyName('');
            setReplyEmail('');
            setReplyingTo(null);
            alert('Your reply has been submitted for approval.');
        } catch (error) {
            console.error("Error adding reply: ", error);
            alert('There was an error submitting your reply. Please try again.');
        }
    };

    const handleLike = async (commentId, isReply = false, replyId = null) => {
        const docRef = isReply
            ? doc(db, `blog/${blogid}/comments/${commentId}/replies/${replyId}`)
            : doc(db, `blog/${blogid}/comments/${commentId}`);

        try {
            await updateDoc(docRef, {
                likes: increment(1)
            });
            // Update local state
            setComments(prevComments => {
                return prevComments.map(comment => {
                    if (isReply && comment.id === commentId) {
                        return {
                            ...comment,
                            replies: comment.replies.map(reply =>
                                reply.id === replyId ? { ...reply, likes: (reply.likes || 0) + 1 } : reply
                            )
                        };
                    } else if (!isReply && comment.id === commentId) {
                        return { ...comment, likes: (comment.likes || 0) + 1 };
                    }
                    return comment;
                });
            });
        } catch (error) {
            console.error("Error updating likes: ", error);
        }
    };

    const renderAuthorName = (author) => {
        if (typeof author === 'string') {
            return author; // For backwards compatibility
        }
        return author.name || 'Unknown Author';
    };


    return (
        <>
        <ScrollToTop />
            <SEO
                title={blog?.title || 'Blog'}
                description={blog?.brief || 'Blog Description'}
                name='Emmanuel Presbyterian Church.'
                type='blog'
            />
            <NavBarComponent />

             <ClearHeader
                title={blog?.title || 'Blog'}
                breadcrumbs={['Home', 'Blogs', blog?.id ]}
            />

            {loading ? <LoadingSpinner /> : (
                <Container className='alignleft text-dark my-5'>
                    { !blog ? <BlogNotFound /> :
                    <Row>
                        <Col sm={8} xs={12}>
                            <img src={blog.imageUrl !== ''? blog.imageUrl :'https://firebasestorage.googleapis.com/v0/b/presby-af279.appspot.com/o/placeholders%2Fpost-placeholder.jpg?alt=media&token=316916e1-dcca-44b6-b9b3-2a510dc3d530' } alt={blog.title} className="img-fluid mb-4" width='100%' />
                            <h1 className='blog-single-title pt-5 pb-3'>{blog.title}</h1>
                            <p className='blog-single-description' dangerouslySetInnerHTML={{ __html: blog.article }}></p>

                            <div className='mb-5'></div>
                            <h3>BLOG DETAILS</h3>
                            <Row>
                                <Col sm={4} xs={12} className="alignleft">
                                    <p><FaCalendarAlt color='var(--redcolor)'/> <span>{blog.date}</span></p>
                                </Col>
                                <Col sm={4} xs={12} className="alignleft">
                                    <p><FaThumbsUp color='var(--redcolor)'/> <span>{blog.likes}</span></p>
                                </Col>
                                <Col sm={4} xs={12} className="alignleft">
                                    <p>Author: {renderAuthorName(blog.author)}</p>
                                </Col>
                            </Row>

                            <div className='mb-5'></div>

                            <h3>Comments</h3>
                            {comments.map(comment => (
                                <div key={comment.id} className="comment-show mb-4">
                                    <p>{new Date(comment.createdAt?.toDate()).toLocaleString()}</p>
                                    <h4>{comment.name}</h4>
                                    <p>{comment.content}</p>
                                    <Button variant="link" onClick={() => handleLike(comment.id)}>
                                        <FaThumbsUp color='var(--redcolor)' /> {comment.likes}
                                    </Button>
                                    <Button variant="link" onClick={() => setReplyingTo(comment.id)}>
                                        <FaReply color='var(--redcolor)' /> Reply
                                    </Button>

                                    {replyingTo === comment.id && (
                                        <Form onSubmit={(e) => { e.preventDefault(); handleReplySubmit(comment.id); }}>
                                            <Form.Group>
                                                <Form.Control
                                                    type="text"
                                                    value={replyName}
                                                    onChange={(e) => setReplyName(e.target.value)}
                                                    placeholder="Your Name"
                                                    required
                                                />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Control
                                                    type="email"
                                                    value={replyEmail}
                                                    onChange={(e) => setReplyEmail(e.target.value)}
                                                    placeholder="Your Email"
                                                    required
                                                />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Control
                                                    as="textarea"
                                                    value={replyContent}
                                                    onChange={(e) => setReplyContent(e.target.value)}
                                                    placeholder="Write your reply..."
                                                    required
                                                />
                                            </Form.Group>
                                            <Button type="submit" className="mt-2">Send Reply</Button>
                                            <Button variant="secondary" className="mt-2 ml-2" onClick={() => setReplyingTo(null)}>Cancel</Button>
                                        </Form>
                                    )}

                                    {comment.replies && comment.replies.map(reply => (
                                        <div key={reply.id} className="reply ml-4 mt-2">
                                            <p>{new Date(reply.createdAt?.toDate()).toLocaleString()}</p>
                                            <h5>{reply.name}</h5>
                                            <p>{reply.content}</p>
                                            <Button variant="link" onClick={() => handleLike(comment.id, true, reply.id)}>
                                                <FaThumbsUp color='var(--redcolor)'/> {reply.likes}
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                            ))}

                            <Form onSubmit={handleCommentSubmit}>
                                <Form.Group>
                                    <Form.Label>Add a comment</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={commentName}
                                        onChange={(e) => setCommentName(e.target.value)}
                                        placeholder="Your Name"
                                        required
                                    />
                                </Form.Group>
                                <div className='pb-2'></div>
                                <Form.Group>
                                    <Form.Control
                                        type="email"
                                        value={commentEmail}
                                        onChange={(e) => setCommentEmail(e.target.value)}
                                        placeholder="Your Email"
                                        required
                                    />
                                </Form.Group>
                                <div className='pb-2'></div>
                                <Form.Group>
                                    <Form.Control
                                        as="textarea"
                                        value={newComment}
                                        onChange={(e) => setNewComment(e.target.value)}
                                        placeholder="Write your comment..."
                                        required
                                    />
                                </Form.Group>
                                <Button type="submit" className="mt-2" style={{
                                        padding: 'clamp(12px, 1.2vw, 18px) clamp(24px, 2.2vw, 36px)',
                                        fontSize: 'clamp(14px, 1vw, 18px)',
                                        backgroundColor: 'transparent',
                                        color: 'var(--txtcolor)',
                                        border: '2px solid var(--redcolor)',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                        fontWeight: 600,
                                        transition: 'all 0.3s ease',
                                    }}>Submit Comment</Button>
                            </Form>
                        </Col>
                        <Col sm={4} xs={12}>
                            <h3>BLOG DETAILS</h3>
                            <h3>{renderAuthorName(blog.author)}</h3>
                            <p><FaCalendarAlt color='var(--redcolor)'/> <span>{blog.date}</span></p>

                            <div className='pb-5'></div>

                            <h3>BRIEF</h3>
                            <p>{blog.brief}</p>

                            <div className='pb-5'></div>

                            <h3>TAGS</h3>
                            <ul style={{listStyleType:"none", paddingLeft:0}}>
                                {blog.tags.map((tag, index) => (
                                    <li key={index}><span className="list-marker">#</span>
                                    {tag}</li>
                                ))}
                            </ul>

                            <div className='pb-5'></div>
                            <h3>CATEGORIES</h3>
                            <ul >
                                {blog.category.map((cat, index) => (
                                    <li key={index}>{cat}</li>
                                ))}
                            </ul>

                            <div className='pb-5'></div>

                            <h3>Other Publications By: </h3>
                            <h3>{renderAuthorName(blog.author)}</h3>
                            {authorPublications.length > 0 ? (
                                <ul style={{listStyleType:"none", paddingLeft:0}}>
                                    {authorPublications.map((publication) => (
                                        <li key={publication.id}>
                                            <Link to={`/blog/${publication.id}`}>
                                                {publication.title}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>No other publications by this author.</p>
                            )}
                        </Col>
                    </Row>
                    }
                </Container>
            )}

            <Footer />
        </>
    );
};

export default BlogSingle;