import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Spinner, Image } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import './Welcome.css';
import { useFirebase } from '../../../Context/FirebaseContext';
import { doc, getDoc } from 'firebase/firestore';
import LazyLoad from 'react-lazyload';
import { GrGroup } from "react-icons/gr";
import { AiOutlineTeam } from "react-icons/ai";
import { LuContact } from "react-icons/lu";
import { FaCross } from "react-icons/fa6";
import { FaHourglassHalf } from "react-icons/fa";

const Welcome = () => {
  const [welcome, setWelcome] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const { db } = useFirebase();

  const isSmallScreen = useMediaQuery({ query: '(max-width: 576px)' });

  useEffect(() => {
    const fetchWelcomeMetadata = async () => {
      try {
        setLoading(true);
        const welcomeDoc = await getDoc(doc(db, 'welcomeNote', 'current'));
        if (welcomeDoc.exists()) {
          setWelcome(welcomeDoc.data());
        } else {
          setError('No welcome data found');
        }
      } catch (error) {
        //console.error("Error fetching welcome data:", error);
        setError('');
      } finally {
        setLoading(false);
      }
    };

    fetchWelcomeMetadata();

    console.log(welcome);
  }, [db]);

  const truncateText = (text, maxLength) => {
    
    if (!text) return ''; // Ensure text is defined before accessing .length
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="text-center mt-5">
        <p className="text-danger">{error}</p>
      </Container>
    );
  }

  if (!welcome) {
    return (
      <Container className="text-center mt-5">
        <h3>We are honoured to have you here.</h3>
      </Container>
    );
  }

  return (
    <Container style={{ width: "100%", overflowX: "hidden" }}>
      <Row>
        <Col xs={12} md={6} className="welcome-text">
          <p className={`welcome-title ${isSmallScreen ? 'small' : ''}`}>
            {welcome.noteTitle}
          </p>
          <div className={`welcome-message ${isSmallScreen ? 'small' : ''}`}>
            <div dangerouslySetInnerHTML={{ 
              __html: truncateText(welcome.welcomeNote || '', 160)
            }} />
            {welcome !== null && welcome.welcomeNote?.length > 160 && (
              <Link 
                to="/about/gettoknowus" 
                className="text-decoration-none red-color"
                style={{ display: 'inline-block', marginTop: '10px' }}
              >
                Read Full Message →
              </Link>
            )}
          </div>

          <div style={{
            display: "flex", 
            flexDirection: 'row', 
            justifyContent: 'flex-start',
            alignItems: 'center', 
            gap: '50px'
          }}>
            <Image src={welcome.leaderImageUrl} roundedCircle style={{width:'80px'}}/>
            <div>
              <p className={`welcome-signature ${isSmallScreen ? 'small' : ''}`}>
                {welcome.leaderName}
              </p>
              <p className={`welcome-position ${isSmallScreen ? 'small' : ''}`}>
                {welcome.leaderTitle}
              </p>
            </div>
          </div>
        </Col>
        
        <Col xs={12} md={6} style={{marginTop:'60px', paddingLeft:"15px", paddingRight:"15px"}}>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <Link to="/about/gettoknowus" className="text-decoration-none">
                <div className="feature" style={{backgroundColor:"var(--redcolor)"}}>
                  <div className="icon">
                    <FaCross className="img-fluid" />
                  </div>
                  <h3 style={{color:"#222"}}>Our Belief</h3>
                  <p>Vision, Mission & Belief.</p>
                </div>
              </Link>
            </div>

            <div className="col-sm-12 col-md-6">
              <Link to="/about/leaders" className="text-decoration-none">
                <div className="feature" style={{backgroundColor:"var(--bluecolor)"}}>
                  <div className="icon">
                    <GrGroup className="img-fluid"/>
                  </div>
                  <h3 style={{color:"#222"}}>Our Team</h3>
                  <p>Know our leadership</p>
                </div>
              </Link>
            </div>

            <div className="col-sm-12 col-md-6">
              <Link to="/about/history" className="text-decoration-none">
                <div className="feature" style={{backgroundColor:"var(--greencolor)"}}>
                  <div className="icon">
                    <FaHourglassHalf className="img-fluid"/>
                  </div>
                  <h3 style={{color:"#222"}}>Our Journey</h3>
                  <p>Know our origins and history.</p>
                </div>
              </Link>
            </div>

            <div className="col-sm-12 col-md-6">
              <Link to="/contact" className="text-decoration-none">
                <div className="feature" style={{backgroundColor:"var(--bodybgcolorgrey)"}}>
                  <div className="icon">
                    <LuContact className="img-fluid"/>
                  </div>
                  <h3 style={{color:"#222"}}>Reach US</h3>
                  <p>We will love to hear from you</p>
                </div>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Welcome;