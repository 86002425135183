// metaUtils.js
export const updateMetaTag = (attrName, attrValue, content) => {
  let metaTag = document.querySelector(`meta[${attrName}="${attrValue}"]`);
  if (metaTag) {
    metaTag.setAttribute("content", content);
  } else {
    metaTag = document.createElement("meta");
    metaTag.setAttribute(attrName, attrValue);
    metaTag.setAttribute("content", content);
    document.head.appendChild(metaTag);
  }
};

export const updateMetaTags = (tags) => {
  tags.forEach((tag) => {
    if (tag.name) {
      updateMetaTag("name", tag.name, tag.content);
    } else if (tag.property) {
      updateMetaTag("property", tag.property, tag.content);
    }
  });
};
