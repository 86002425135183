import React, { useState, useEffect } from 'react';
import NavBarComponent from '../Components/GeneralNav/Nav';
import Footer from '../Components/Footer/Footer';
import { Container, Row, Col, Card, Button, Modal, Tab, Tabs, Spinner, Image } from 'react-bootstrap';
import SEO from '../Components/SEO';
import ClearHeader from '../Layouts/PageHeader/ClearHeader';
import ScrollToTop from '../Components/ScrollToTop';
import { useFirebase } from '../Context/FirebaseContext';
import { doc, getDoc } from 'firebase/firestore';
import { useMediaQuery } from 'react-responsive';
import LazyLoad from 'react-lazyload';
import {
    FaEye,
    FaCrosshairs,
    FaCross,
    FaPrayingHands,
    FaChurch,
    FaBible,
    FaArrowRight,
    FaHeart,
    FaHandHoldingHeart,
    FaUsers
} from 'react-icons/fa';
import { MdGroups } from 'react-icons/md';
import logo from '../images/pcg.png'; // Adjust the path based on your file structure

const LoadingSpinner = () => (
  <div 
    style={{ 
      height: '100dvh', 
      display: 'flex', 
      flexDirection: 'column',
      justifyContent: 'center', 
      alignItems: 'center',
      gap: '2rem'
    }}
  >
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {/* Logo - Replace src with your actual logo path */}
      <img 
        src={logo}  
        alt="Logo" 
        style={{ 
          width: '60px', 
          height: '60px', 
          objectFit: 'contain',
          position: 'relative',
          zIndex: 2
        }} 
      />
      {/* Spinner positioned absolutely around the logo */}
      <div style={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)',
        width: '100px',
        height: '100px'
      }}>
        <Spinner 
          animation="border" 
          variant="danger"
          style={{ 
            width: '100%', 
            height: '100%',
            borderWidth: '4px',
            opacity: 0.7
          }} 
        />
      </div>
    </div>
  </div>
);

const styles = `
/* VisionMissionBeliefs Styles */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes slideIn {
  from { transform: translateX(-20px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes pulseIcon {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.vision-mission-section {
  padding: 80px 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
}

.section-header {
  text-align: center;
  margin-bottom: 4rem;
  animation: fadeIn 0.8s ease-out;
}

.section-title {
  color: #2c3e50;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.section-subtitle {
  color: #6c757d;
  font-size: 1.1rem;
  max-width: 600px;
  margin: 0 auto;
}

.main-card {
  border: none;
  border-radius: 15px;
  transition: all 0.3s ease;
  height: 100%;
  background: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.8s ease-out;
}

.main-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.card-icon {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: var(--redcolor);
  animation: pulseIcon 2s infinite;
}

.vm-card-title {
  color: #2c3e50;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.vm-card-text {
  color: #5a6a7a;
  font-size: 1rem;
  line-height: 1.6;
}

.learn-more-btn {
  margin-top: 1rem;
  background-color: transparent;
  border: 2px solid var(--redcolor);
  color: var(--redcolor);
  transition: all 0.3s ease;
}

.learn-more-btn:hover {
  background-color: var(--redcolor);
  color: white;
}

.beliefs-section {
  margin-top: 6rem;
  padding: 3rem 0;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.belief-tabs {
  border: none;
  margin-bottom: 2rem;
}

.belief-tabs .nav-link {
  color: #5a6a7a;
  border: none;
  padding: 1rem 2rem;
  transition: all 0.3s ease;
}

.belief-tabs .nav-link.active {
  color: var(--redcolor);
  background-color: transparent;
  border-bottom: 3px solid var(--redcolor);
}

.belief-card {
  border: none;
  border-radius: 12px;
  background: white;
  transition: all 0.3s ease;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  animation: slideIn 0.5s ease-out;
}

.belief-card:hover {
  transform: translateX(10px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.belief-icon {
  color: var(--redcolor);
  font-size: 1.5rem;
  margin-right: 1rem;
}

.belief-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #2c3e50;
}

.belief-text {
  font-size: 1rem;
  color: #5a6a7a;
}

.stat-card {
  text-align: center;
  padding: 2rem;
  margin-top: 3rem;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  animation: fadeIn 1s ease-out;
}

.stat-icon {
  font-size: 2rem;
  color: var(--redcolor);
  margin-bottom: 1rem;
}

.stat-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.stat-label {
  color: #6c757d;
  font-size: 1rem;
}

.detail-modal .modal-content {
  border-radius: 15px;
  border: none;
}

.detail-modal .modal-header {
  border-bottom: none;
  padding: 2rem 2rem 1rem;
}

.detail-modal .modal-body {
  padding: 1rem 2rem 2rem;
}

@media (max-width: 768px) {
  .vision-mission-section {
    padding: 40px 0;
  }

  .section-title {
    font-size: 2rem;
  }

  .vm-card-title {
    font-size: 1.3rem;
  }

  .belief-tabs .nav-link {
    padding: 0.75rem 1rem;
  }
}


/* WelcomeSection Styles */
.welcome-section {
    padding: 60px 0;
    background-color: #fff;
  }

  .welcome-section .section-subtitle { /* To avoid conflict, be more specific if needed */
    color: #4a90e2;
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .welcome-section .section-title { /* To avoid conflict, be more specific if needed */
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: #2c3e50;
  }

  .welcome-text {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #5a6a7a;
    padding-top: 40px;
  }

  blockquote {
      font-size: 1.25rem;
    font-style: italic;
    color: #34495e;
    line-height: 1.6;
    text-align:center;
  }

  blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 2em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;

  }
  blockquote p {
    display: inline;
  }

  .quote-text {
    font-size: 1.25rem;
    font-style: italic;
    color: #34495e;
    line-height: 1.6;
  }

  .quote-icon {
    color: #4a90e2;
    opacity: 0.5;
  }

  .leader-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }



  .leader-name {
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 0.25rem;
    color: #2c3e50;
  }

  .leader-title {
    font-size: 0.9rem;
    color: #6c757d;
    margin: 0;
  }

  @media (max-width: 991.98px) {
    .welcome-section .section-title { /* To avoid conflict, be more specific if needed */
      font-size: 2rem;
    }

    .welcome-section {
      padding: 40px 0;
    }
  }
`;

const KnowUS = () => {
    // State from WelcomeSection
    const [welcome, setWelcome] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const isSmallScreen = useMediaQuery({ query: '(max-width: 576px)' });

    // State from VisionMissionBeliefs
    const [content, setContent] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [activeTab, setActiveTab] = useState('core');

    // Combined Loading and Error State
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { db } = useFirebase();

    useEffect(() => {
        const fetchPageData = async () => {
            setLoading(true);
            setError(null); // Reset error on new fetch
            try {
                const welcomeDoc = await getDoc(doc(db, 'welcomeNote', 'current'));
                const vmbDoc = await getDoc(doc(db, 'aboutContent', 'vmb'));

                if (welcomeDoc.exists() && vmbDoc.exists()) {
                    setWelcome(welcomeDoc.data());
                    setContent(vmbDoc.data());
                } else {
                    setError('Content not found');
                }
            } catch (err) {
                console.error("Error fetching content:", err);
                setError('Failed to load content');
            } finally {
                setLoading(false);
            }
        };

        fetchPageData();
    }, [db]);


    // Functions from VisionMissionBeliefs
    const handleCardClick = (type) => {
        setModalContent({
            title: type === 'vision' ? 'Our Vision'
                : type === 'mission' ? 'Our Mission'
                    : 'Our Values',
            content: content[type],
            icon: type === 'vision' ? <FaEye />
                : type === 'mission' ? <FaCrosshairs />
                    : <FaCross />
        });
        setModalShow(true);
    };

    const getBeliefIcon = (index) => {
        const icons = [FaPrayingHands, FaBible, FaChurch, MdGroups];
        const IconComponent = icons[index % icons.length];
        return <IconComponent className="belief-icon" />;
    };


    // Functions from WelcomeSection
    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) return text;
        return text.substr(0, maxLength) + '...';
    };


    return (
        <>
            <ScrollToTop />
            
           
            {loading ? (
               
                    <LoadingSpinner />
                
            ) : error ? (
              
                <Container style={{ marginTop: "60px", minHeight: '300px' }} className="text-center mt-5">
                    <p className="text-danger">{error}</p>
                </Container>
            ) : (
                <>
                  <SEO
                      title='Know Us'
                      description='The official website of the Emmanuel Presbyterian Church Kasoa'
                      name='Emmnuel Presbyterian Church.'
                      type='profile'
                  />
                  <NavBarComponent />
                  <ClearHeader
                        title="Get To Know Us"
                        breadcrumbs={['Home', 'Know Us']}
                    />
                    <style>{styles}</style>
                    <Container style={{ marginTop: "60px" }}>

                        {/* Welcome Section JSX */}
                        <section className="welcome-section">
                            <Container>
                                <Row className="align-items-start">
                                    <Col lg={6}>
                                        <div className="pe-lg-4">

                                            <h2 className="section-title" style={{ textAlign: 'left' }}>
                                                {welcome.noteTitle}
                                            </h2>

                                            <div className="welcome-text mb-4">
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: expanded ? welcome.welcomeNote : truncateText(welcome.welcomeNote, 500)
                                                    }}
                                                />
                                                {welcome.welcomeNote.length > 500 && (
                                                    <Button
                                                        variant="link"
                                                        onClick={toggleExpand}
                                                        className="p-0 text-decoration-none text-primary"
                                                        style={{ color: 'var(--redcolor)' }}
                                                    >
                                                        {expanded ? 'Show Less' : 'Read More'}
                                                    </Button>
                                                )}
                                            </div>

                                            <div className="d-flex align-items-start mt-4" style={{ textAlign: "left" }}>

                                                <div className="leader-info">
                                                    <p className="leader-name">
                                                        {welcome.leaderName}
                                                    </p>
                                                    <p className="leader-title">
                                                        {welcome.leaderTitle}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg={6} className="mt-4 mt-lg-0">
                                        <div className="position-relative">
                                            <LazyLoad>
                                                <img
                                                    src={welcome.leaderImageUrl}
                                                    alt="Welcome"
                                                    style={{width: '100%', height: 'auto'}}
                                                    className="img-fluid  "
                                                    onError={(e) => {
                                                        e.target.src = "/api/placeholder/600/400"; // Fallback image
                                                    }}
                                                />
                                            </LazyLoad>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>


                        {/* VisionMissionBeliefs JSX */}
                        <section className="vision-mission-section">
                            <Container>
                                <div className="section-header">
                                    <h2 className="section-title">Our Foundation</h2>
                                    <p className="section-subtitle">Guided by faith, driven by purpose, united in love</p>
                                </div>

                                <Row className="g-4">
                                    {[
                                        { type: 'vision', title: 'Our Vision', icon: FaEye },
                                        { type: 'mission', title: 'Our Mission', icon: FaCrosshairs },
                                        { type: 'values', title: 'Our Values', icon: FaCross }
                                    ].map((item) => (
                                        <Col md={4} key={item.type}>
                                            <Card className="main-card p-4 text-center">
                                                <Card.Body>
                                                    <item.icon className="card-icon" />
                                                    <h3 className="vm-card-title">{item.title}</h3>
                                                    <p className="vm-card-text">
                                                        {content[item.type]?.substring(0, 150)}...
                                                    </p>
                                                    <Button
                                                        className="learn-more-btn"
                                                        onClick={() => handleCardClick(item.type)}
                                                    >
                                                        Learn More <FaArrowRight className="ms-2" />
                                                    </Button>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>

                                <div className="beliefs-section">
                                    <h3 className="text-center mb-4">What We Believe</h3>
                                    <Tabs
                                        activeKey={activeTab}
                                        onSelect={(k) => setActiveTab(k)}
                                        className="belief-tabs justify-content-center mb-4"
                                    >
                                        <Tab eventKey="core" title="Core Beliefs" />
                                        <Tab eventKey="values" title="Values" />
                                        <Tab eventKey="practices" title="Practices" />
                                    </Tabs>

                                    <Row>
                                        {content.beliefs
                                            ?.filter(belief => belief.category === activeTab)
                                            .map((belief, index) => (
                                                <Col md={6} key={index}>
                                                    <Card className="belief-card p-3">
                                                        <Card.Body className="d-flex">
                                                            {getBeliefIcon(index)}
                                                            <div>
                                                                <h4 className="belief-title">{belief.title}</h4>
                                                                <p className="belief-text mb-0">{belief.description}</p>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            ))}
                                    </Row>
                                </div>

                                <Row className="mt-5">
                                    {[
                                        { icon: FaUsers, number: '1000+', label: 'Community Members' },
                                        { icon: FaHeart, number: '50+', label: 'Ministry Programs' },
                                        { icon: FaHandHoldingHeart, number: '100+', label: 'Volunteers' }
                                    ].map((stat, index) => (
                                        <Col md={4} key={index}>
                                            <div className="stat-card">
                                                <stat.icon className="stat-icon" />
                                                <div className="stat-number">{stat.number}</div>
                                                <div className="stat-label">{stat.label}</div>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Container>

                            <Modal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                                size="lg"
                                centered
                                className="detail-modal"
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title className="d-flex align-items-center">
                                        {modalContent.icon}
                                        <span className="ms-2">{modalContent.title}</span>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>{modalContent.content}</p>
                                </Modal.Body>
                            </Modal>
                        </section>
                    </Container>
                </>
            )}

            <Footer />
        </>
    );
};

export default KnowUS;
/*import React from 'react';
import NavBarComponent from '../Components/GeneralNav/Nav';
import Footer from '../Components/Footer/Footer';
import { Container } from 'react-bootstrap';
import SEO from '../Components/SEO';
import ClearHeader from '../Layouts/PageHeader/ClearHeader';
import HeaderImage from "../images/headers/s2.jpg"
import WelcomeSection from '../Layouts/About/WelcomeSection';
import VisionMissionBeliefs from '../Layouts/About/VisionMissionBeliefs';
import ScrollToTop from '../Components/ScrollToTop';

const KnowUS = () => {
  return (
    <>
    <ScrollToTop />
        <SEO
            title='Know Us'
            description='The official website of the Emmanuel Presbyterian Church Kasoa'
            name='Emmnuel Presbyterian Church.'
            type='profile' 
        />
        <NavBarComponent />
        <ClearHeader 
            title="Get To Know Us" 
            breadcrumbs={['Home', 'Know Us']} 
        />
        <Container style={{marginTop:"60px"}}>
            <WelcomeSection />
            <VisionMissionBeliefs />
        </Container>
      
      <Footer />
    </>
  );
};

export default KnowUS;
*/