import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFirebase } from '../Context/FirebaseContext';
import { collection, query, where, getDocs, orderBy, limit, startAfter } from 'firebase/firestore';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import PageHeader from '../Layouts/PageHeader/PageHeader';
import NavBarComponent from '../Components/GeneralNav/Nav';
import SEO from '../Components/SEO';
import { FaCalendarAlt, FaUser } from 'react-icons/fa';
import Footer from '../Components/Footer/Footer';
import Pagination from 'react-bootstrap/Pagination';
import HeaderImage from "../images/headers/s2.jpg";
import ClearHeader from '../Layouts/PageHeader/ClearHeader';
import ScrollToTop from '../Components/ScrollToTop';

import logo from '../images/pcg.png'; // Adjust the path based on your file structure

const LoadingSpinner = () => (
  <div
    style={{
      height: '100dvh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '2rem'
    }}
  >
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {/* Logo - Replace src with your actual logo path */}
      <img
        src={logo}
        alt="Logo"
        style={{
          width: '60px',
          height: '60px',
          objectFit: 'contain',
          position: 'relative',
          zIndex: 2
        }}
      />
      {/* Spinner positioned absolutely around the logo */}
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100px',
        height: '100px'
      }}>
        <Spinner
          animation="border"
          variant="danger"
          style={{
            width: '100%',
            height: '100%',
            borderWidth: '4px',
            opacity: 0.7
          }}
        />
      </div>
    </div>
  </div>
);


const Blogs = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { db } = useFirebase();
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [lastVisible, setLastVisible] = useState(null);
    const blogsPerPage = 3;

    useEffect(() => {
        fetchBlogs();
    }, [currentPage]);

    const fetchBlogs = async () => {
        setLoading(true);
        setError(null);
        try {
            let blogsQuery = query(
                collection(db, 'blog'),
                where('status', '==', 'publish'),
                where('deleted', '==', false),
                orderBy('createdOn', 'desc'),
                limit(blogsPerPage)
            );

            if (lastVisible && currentPage > 1) {
                blogsQuery = query(
                    collection(db, 'blog'),
                    where('status', '==', 'publish'),
                    where('deleted', '==', false),
                    orderBy('createdOn', 'desc'),
                    startAfter(lastVisible),
                    limit(blogsPerPage)
                );
            }

            const querySnapshot = await getDocs(blogsQuery);
            const fetchedBlogs = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            setBlogs(fetchedBlogs);
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);

            if (currentPage === 1) {
                const totalDocs = await getDocs(query(
                    collection(db, 'blog'),
                    where('status', '==', 'publish'),
                    where('deleted', '==', false)
                ));
                setTotalPages(Math.ceil(totalDocs.size / blogsPerPage));
            }
        } catch (err) {
            setError('Failed to fetch blogs. Please try again later.');
            console.error('Error fetching blogs:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleEventClick = (title, blogId) => {
        navigate(`/blog/${createSlug(title)}/${blogId}`);
    };

    const createSlug = (string) => {
        string = string.toLowerCase();
        string = string.replace(/[^a-z0-9 ]/g, '');
        string = string.replace(/\s+/g, '-');
        string = string.replace(/^-|-$/g, '');
        return string === '' ? 'slug' : string;
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        navigate(`/blogs?page=${pageNumber}`);
    };

    const renderAuthorName = (author) => {
        if (typeof author === 'string') {
          return author; // For backwards compatibility
        }
        return author.name || 'Unknown Author';
    };

    return (
        <>
        <ScrollToTop />
            <SEO
                title="Blogs"
                description="Read our latest blogs and articles"
                name='Emmanuel Presbyterian Church.'
                type='blogs'
            />
            <NavBarComponent />

            <ClearHeader
                title="Blogs"
                breadcrumbs={['Home', 'Blogs']}
            />
            <div style={{marginBottom:"100px"}}></div>

            {loading ? <LoadingSpinner /> : (
                <Container className='alignleft text-dark nobg'>
                    {error ? (
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    ) : blogs.length === 0 ? (
                        <div className="text-center">
                            <h2>No blogs available at the moment.</h2>
                        </div>
                    ) : (
                        blogs.map((blog) => (
                            <div key={blog.id} className='blog-item text-dark mb-5 nobg'>
                                <div className="mb-3">
                                    <img src={blog.imageUrl !== '' ? blog.imageUrl :'https://firebasestorage.googleapis.com/v0/b/presby-af279.appspot.com/o/placeholders%2Fpost-placeholder.jpg?alt=media&token=316916e1-dcca-44b6-b9b3-2a510dc3d530'} alt={blog.title} className="img-fluid w-100" style={{maxHeight: '300px', objectFit: 'cover'}} />
                                </div>
                                <p>
                                    <FaUser className="me-2" color='var(--redcolor)'/>
                                    <span className="me-3">{renderAuthorName(blog.author)}</span>
                                    <FaCalendarAlt className="me-2" color='var(--redcolor)'/>
                                    <span>{new Date(blog.createdOn.toDate()).toLocaleDateString()}</span>
                                </p>
                                <h2>{blog.title}</h2>
                                <p>{blog.brief}</p>
                                <Button onClick={() => handleEventClick(blog.title, blog.id)} variant="link" style={{textDecoration:"none", color:"var(--redcolor)", padding:0, fontWeight:600}}>READ ARTICLE</Button>
                            </div>
                        ))
                    )}

                    {totalPages > 1 && (
                        <Pagination className="justify-content-center mt-4">
                            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

                            {[...Array(totalPages)].map((_, index) => (
                                <Pagination.Item
                                    key={index + 1}
                                    active={index + 1 === currentPage}
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </Pagination.Item>
                            ))}

                            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                            <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
                        </Pagination>
                    )}
                </Container>
            )}

            <div style={{marginTop:"20px",marginBottom:"50px"}}></div>
            <Footer />
        </>
    );
};

export default Blogs;