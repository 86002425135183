// home.jsx
import React from 'react';
import NavBarComponent from '../Layouts/IndexLayout/Nav/Nav';
import { Container } from 'react-bootstrap';
import AudioBannerComponent from '../Layouts/IndexLayout/Audio/AudioBannerComponent';
import Motto from '../Layouts/IndexLayout/Motto/Motto';
import Welcome from '../Layouts/IndexLayout/Welcome/Welcome';
import OurGroups from '../Layouts/IndexLayout/Groups/OurGroups';
import Footer from '../Components/Footer/Footer';
import CallToAction from '../Layouts/IndexLayout/CallToAction/CallToAction';
import BlogUpdate from '../Layouts/IndexLayout/Blog/BlogUpdate';
import BibleVerse from '../Layouts/IndexLayout/Bible/BibleVerse';
import Schedule from '../Layouts/IndexLayout/Activity/Schedule';
import Annoucement from '../Layouts/IndexLayout/Annoucements/Annoucement';
import BannerCarouselComponent from '../Layouts/IndexLayout/Carousel/BannerCarouselComponent';
import ResponsiveCarousel from '../Layouts/IndexLayout/Carousel/ResponsiveCarousel';
import FadeInSection from '../Components/FadeInSection';
import BibleQuoteSection from '../Layouts/IndexLayout/Bible/BibleQuoteSection';
import EventsCarousel from '../Layouts/IndexLayout/Blog/EventsCarousel';
import ScrollToTop from '../Components/ScrollToTop';
import SocialMetaManager from '../Components/SocialMetaManager';
import MetaChurch from '../images/metachurch.png'

const Home = () => {
  return (
    <>
    <SocialMetaManager
        title={'Home'}
        description={'Welcome to our church. We offer weekly services, Bible studies, and community programs in a warm, inclusive environment. Join us for Sunday worship, fellowship opportunities, and special events throughout the year.'}
        image={MetaChurch}
        url={`https://emmanuelpresbykasoa.org/`}
      />
     <ScrollToTop />
        
        <NavBarComponent />
        <ResponsiveCarousel />
        <AudioBannerComponent />
        
        <Container className='container-overflow-width ' style={{paddingTop:"20px"}}>
            <FadeInSection>
                <Motto />
            </FadeInSection>
            <FadeInSection>
                <Welcome />
            </FadeInSection>
            <FadeInSection>
                <OurGroups/>
            </FadeInSection>
        </Container>

        <Container fluid style={{  textAlign:"left", minHeight:"50px", color:"whitesmoke"}} className='bg-dark container-overflow-width'>
            <FadeInSection>
                <CallToAction/>
            </FadeInSection>
        </Container>

        <Container  fluid style={{  textAlign:"left"}} className='container-overflow-width '>
            <FadeInSection>
                <BlogUpdate />
            </FadeInSection>
            
        </Container>
            
        
        
        <FadeInSection>
            <Schedule/>
        </FadeInSection>
        
        <FadeInSection>
            <BibleVerse />
        </FadeInSection>
    
        <Footer/>

    </>
  );
};

export default Home;

